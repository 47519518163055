import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { VStack, Button, HStack, useToast } from '@chakra-ui/react';
import { ProjectIds } from '~/pages/projects/types';
import { useMeasure } from '~/pages/projects/hooks/useMeasure';
import { DeviceUsage, DeviceUsageModal } from './DeviceUsage';
import { ElectricityMeter, ElectricityMeterModal } from './ElectricityMeter';
import { DeviceUsageDownload } from './DeviceUsageDownload';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { useModal } from '~/hooks';
import { OverrideActions } from '~/components/Layout/PageHeader';
import { MultiModal } from '~/components/MultiModal';
import { HasPermission } from '~/layout/HasPermission';
import { useProjectParams } from '../../hooks/useProjectParams';
import { Wattro } from '~/components/Wattro';
import { useWattro } from '../Wattro/hooks/useWattro';
import {
    CommandResult,
    ListDeviceUsageDocument,
    ListElectricityMeterDocument,
    SelectSubprojectResidentialUnitsDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';

export const Modals = {
    DeviceUsage: 'DeviceUsage',
    ElectricityMeter: 'ElectricityMeter',
};

export const DeviceUsageAndElectricalMeters = (props?: ProjectIds) => {
    const toast = useToast();
    const { projectId, subprojectId }: any = useProjectParams();
    const { subprojectResidentialUnits } = useMeasure({ projectId, subprojectId });
    const refetchQueries = useMemo(
        () =>
            subprojectResidentialUnits.reduce((acc: any, unit: any) => {
                const context = { clientName: 'ucpw' };
                const variables = {
                    limit: 250,
                    filter: {
                        subprojektWohneinheitId: unit.id,
                        subprojektWohneinheit: {
                            subprojektId: subprojectId,
                            subprojekt: {
                                projektId: projectId,
                            },
                        },
                    },
                };
                return [
                    ...acc,
                    { query: ListDeviceUsageDocument, context, variables },
                    {
                        query: ListElectricityMeterDocument,
                        context,
                        variables,
                    },
                ];
            }, []),
        [subprojectResidentialUnits]
    );

    const { wattroUpdateDeploymentsToProjekt, loading } = useWattro({
        refetchQueries: [
            {
                query: SelectSubprojectResidentialUnitsDocument,
                context: { clientName: 'ucpw' },
                variables: {
                    limit: 50,
                    orderBy: [
                        { wohneinheit: { istGesamtobjekt: SortOrder.Desc } },
                        { wohneinheit: { bezeichnung: SortOrder.Asc } },
                    ],
                    filter: { subprojektId: subprojectId },
                },
            },
            ...refetchQueries,
        ],
    });

    useModal({
        defaultState: { modals: { activeModal: Modals.DeviceUsage } },
        component: (
            <MultiModal>
                <DeviceUsageModal id={Modals.DeviceUsage} />
                <ElectricityMeterModal id={Modals.ElectricityMeter} />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer(Modals.DeviceUsage),
            row: rowReducer,
        }),
    });

    return (
        <>
            <OverrideActions>
                <HasPermission resource="project.deviceUsage" permission="view">
                    <HStack>
                        <DeviceUsageDownload {...{ projectId, subprojectId }} />
                        <Button
                            size="sm"
                            isLoading={loading}
                            onClick={async () => {
                                const { data } = await wattroUpdateDeploymentsToProjekt({
                                    variables: { projektId: projectId },
                                });
                                const error = (
                                    data?.app?.wattroUpdateDeploymentsToProjekt as CommandResult
                                )?.error;

                                if (error) {
                                    Sentry.captureMessage(
                                        `${error.code}: ${error.message} [app.wattroUpdateDeploymentsToProjekt]`,
                                        'error'
                                    );
                                    toast({
                                        title: error.code,
                                        description: error.message,
                                        status: 'error',
                                        duration: 4000,
                                        isClosable: true,
                                    });
                                }
                            }}
                            colorScheme="blue"
                        >
                            <Wattro mr={2} shorten />
                            Einsatz aktualisieren
                        </Button>
                    </HStack>
                </HasPermission>
            </OverrideActions>
            <VStack spacing={6} p={6}>
                {subprojectResidentialUnits.map((unit, idx) => (
                    <React.Fragment key={unit.id}>
                        <DeviceUsage
                            dataTestId={`device-usage-${idx}`}
                            projectId={projectId}
                            subprojectId={subprojectId}
                            residentialUnit={unit}
                        />
                        <ElectricityMeter
                            dataTestId={`electricity-meter-${idx}`}
                            projectId={projectId}
                            subprojectId={subprojectId}
                            residentialUnit={unit}
                        />
                    </React.Fragment>
                ))}
            </VStack>
        </>
    );
};

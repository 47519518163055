import { useState } from "react";
import { useModal } from '~/hooks';
import { MultiModal } from '~/components/MultiModal';
import { GeneralAgreementPositionsModal } from '~/pages/master-data/ui/GeneralAgreementPositionsModal';
import { GeneralAgreementsModal } from '~/pages/master-data/ui/GeneralAgreementsModal';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { Rahmenvertrag } from "~/gql/ucpw/graphql";

export function useGeneralAgreements() {
    const [activeGeneralAgreement, setActiveGeneralAgreement] = useState<Rahmenvertrag | null>(null);

    useModal({
        defaultState: { modals: { activeModal: 'GeneralAgreementPositionsModal' } },
        component: (
            <MultiModal>
                <GeneralAgreementsModal id="GeneralAgreementsModal" />
                <GeneralAgreementPositionsModal id="GeneralAgreementPositionsModal" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('GeneralAgreementPositionsModal'),
            row: rowReducer,
        }),
    });

    return {
        activeGeneralAgreement,
        setActiveGeneralAgreement,
    };
}

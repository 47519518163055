import React from 'react'
import { addDays, format } from 'date-fns'
import { VStack, Checkbox, SimpleGrid, useBoolean } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { DataTable, Card, CardActions, CardContent, CardHeader } from '@ucc/react/ui';
import { Form } from '~/components/Form/Form';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { useSecurity } from '@ucc/react/security';
import { useOpenTasks } from '../hooks/useOpenTasks';
import { selects } from '~/meta/data/selects.gql';
import { useModal } from '~/hooks';
import { TaskModal } from './Task/TaskModal';
import { PageHeader } from '~/components/Layout/PageHeader';
import { join } from '~/utils';

export function OpenTasks({ title }: { title: string }) {
    useModal(<TaskModal />);
    const { viewer } = useSecurity();
    const [ownTasks, setOwnTasks] = useBoolean();
    const [resubmission, setResubmission] = useBoolean();

    const { control, watch, setValue } = useForm();

    const { branch, role, taskFunction, employee } = watch();
    const branchId = branch?.value;
    const roleId = role?.value;
    const functionId = taskFunction?.value
    const employeeId = viewer?.app?.mitarbeiterId;

    const isOwnTasks = employeeId && ownTasks;


    const filter = {
        erledigtExists: false,
        zuErledigenNotInFuture: !resubmission,
        ...(resubmission && { zuErledigenFrom: format(addDays(new Date(), 1), 'yyyy-MM-dd') }),
        ...(employee?.value && { mitarbeiterIdSachbearbeiter: employee?.value }),
        ...(isOwnTasks && { mitarbeiterIdSachbearbeiter: employeeId }),
        ...(functionId && { funktionId: functionId }),
        ...(roleId && { rolleId: roleId }),
        ...(branchId && {
            subprojekt: {
                projekt: { niederlassungId: branchId },
            },
        }),
    }

    React.useEffect(() => {
        employee === null && setOwnTasks.off()
    }, [employee])

    const dataTableProps = useOpenTasks({ filter })

    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
            <PageHeader.Actions>
                <Form>
                    <SimpleGrid columns={3} spacing={6} w="675px">
                        <QuerySelect
                            control={control}
                            isClearable
                            name="branch"
                            placeholder="Niederlassung"
                            query={selects.branches}
                            mapOptions={(item) => ({
                                value: item?.value,
                                label: `${item?.nummer} - ${item?.bezeichnung}`,
                            })}
                        />
                        <QuerySelect
                            control={control}
                            isClearable
                            name="taskFunction"
                            placeholder="Funktion"
                            query={selects.functions}
                        />
                        <QuerySelect
                            control={control}
                            isClearable
                            name="employee"
                            placeholder="Mitarbeiter"
                            query={selects.employees}
                            onSelect={(value: any) => employeeId && value?.value === employeeId ? setOwnTasks.on() : setOwnTasks.off()}
                            mapOptions={(item: any) => ({
                                value: item?.value,
                                label: join([item?.name, item?.vorname]),
                            })}
                        />
                    </SimpleGrid>
                </Form>
            </PageHeader.Actions>
        </PageHeader>
        <VStack p={6} align="flex-start">
            <Card boxShadow="none">
                <CardHeader>
                    <CardActions>
                        <Checkbox
                            mr={3}
                            isChecked={ownTasks}
                            onChange={(e) => {
                                setOwnTasks.toggle()
                                employeeId && setValue('employee', e?.target?.checked ? { value: employeeId } : null)
                            }}>
                            Meine Aufgaben
                        </Checkbox>
                        <Checkbox
                            isChecked={resubmission}
                            onChange={setResubmission.toggle}>
                            Wiedervorlagen anzeigen
                        </Checkbox>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </VStack>
    </>;
}

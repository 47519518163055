import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    ListGeneralAgreementPostionDocument,
    CreateGeneralAgreementPositionDocument,
    UpdateGeneralAgreementPositionDocument,
    DeleteGeneralAgreementPositionDocument,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/master-data/meta/data/generalAgreements.schema';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useModal } from '~/hooks/useModal';
import { usePermission } from '~/hooks/usePermission';

const LIMIT = 500;

export const useSearchedAgreementPositions = (generalAgreementId?: number, filter: any = {}) => {
    const { canEdit, canDelete } = usePermission('masterData.agreements');

    // ===============================================s
    // LISTING
    // ===============================================

    const context = { clientName: 'ucpw' };
    const variables = {
        limit: LIMIT,
        filter: { rahmenvertragId: generalAgreementId, ...filter },
    };
    const { data, loading: listLoading } = useQuery(ListGeneralAgreementPostionDocument, {
        context,
        variables,
    });

    const positions: any = data?.items?.items || [];

    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListGeneralAgreementPostionDocument,
        variables
    );

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = listLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // CONTROLS
    // ===============================================

    const setGeneralAgreementPositionsModal = () =>
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'GeneralAgreementPositionsModal',
                props: {
                    createMutation,
                    updateMutation,
                    loading,
                    agreementId: generalAgreementId,
                },
            },
        });

    const onClick = React.useCallback(
        (row: any) => {
            setGeneralAgreementPositionsModal();
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen, setGeneralAgreementPositionsModal]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: { onClick },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.positions.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.positions.hiddenColumns;

    const onCreate = React.useCallback(() => {
        setGeneralAgreementPositionsModal();
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    }, [dispatch, onOpen, createMutation, updateMutation, loading]);

    return {
        onCreate,
        data: positions,
        hiddenColumns,
        pageSize: LIMIT,
        columns,
        loading,
        onClick,
        plusButtonProps: {
            onClick: onCreate,
            children: 'RV-Position anlegen',
        },
    };
};

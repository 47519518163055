import { VStack } from '@chakra-ui/react';
import { DataTable, Card, CardContent } from '@ucc/react/ui';
import { PageHeader, Title } from '~/components/Layout/PageHeader';
import { useEmergencyProjects } from '~/pages/projects/ui/Wattro/hooks/useEmergencyProjects';

export function EmergencyProjects({ title }: { title: string }) {
    const { data, columns, loading } = useEmergencyProjects();
    return (
        <>
            <PageHeader>
                <Title>{title}</Title>
            </PageHeader>
            <VStack spacing={6} p={6}>
                <Card boxShadow="none">
                    <CardContent>
                        <DataTable columns={columns} data={data} loading={loading} pageSize={500} />
                    </CardContent>
                </Card>
            </VStack>
        </>
    );
}

import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    ListGeneralAgreementPostionDocument,
    CreateGeneralAgreementPositionDocument,
    UpdateGeneralAgreementPositionDocument,
    DeleteGeneralAgreementPositionDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/master-data/meta/data/generalAgreements.schema';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useModal } from '~/hooks/useModal';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { usePermission } from '~/hooks/usePermission';

export const useAgreementPositions = (generalAgreementId?: number) => {
    const { canEdit, canDelete } = usePermission('masterData.agreements');

    // ===============================================s
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(
        ListGeneralAgreementPostionDocument,
        {
            context: { clientName: 'ucpw' },
        }
    );
    const positions: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        orderBy: [{ nr: SortOrder.Asc }],
        filter: { rahmenvertragId: generalAgreementId, beschreibungPrefix: '' },
    });
    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListGeneralAgreementPostionDocument,
        variables
    );

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteGeneralAgreementPositionDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // CONTROLS
    // ===============================================

    const setGeneralAgreementPositionsModal = () =>
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'GeneralAgreementPositionsModal',
                props: {
                    createMutation,
                    updateMutation,
                    refetch,
                    loading,
                    agreementId: generalAgreementId,
                },
            },
        });

    const onClick = React.useCallback(
        (row: any) => {
            setGeneralAgreementPositionsModal();
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen, setGeneralAgreementPositionsModal]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: { onClick },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.positions.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.positions.hiddenColumns;

    const onCreate = React.useCallback(() => {
        setGeneralAgreementPositionsModal();
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    }, [dispatch, onOpen, createMutation, updateMutation, refetch, loading]);

    return {
        onCreate,
        data: positions,
        pageCount,
        hiddenColumns,
        columns,
        loading,
        onClick,
        fetchData: fetchDataWithFilters,
        plusButtonProps: {
            onClick: onCreate,
            children: 'RV-Position anlegen',
        },
    };
};

import React from "react";
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { useAgreements } from '~/pages/master-data/ui/GeneralAgreements/hooks/useAgreements';
import { HasPermission } from '~/layout/HasPermission';
import { Rahmenvertrag } from "~/gql/ucpw/graphql";

type Props = {
    activeGeneralAgreement?: Rahmenvertrag;
    setActiveGeneralAgreement?: (arg: Rahmenvertrag) => void
}

export function Agreements({ activeGeneralAgreement, setActiveGeneralAgreement }: any) {
    const { plusButtonProps, standard, ...tableProps } = useAgreements();

    React.useEffect(() => {
        !activeGeneralAgreement && standard && setActiveGeneralAgreement(standard);
    }, [activeGeneralAgreement, standard]);

    return (
        <Card boxShadow="none">
            <CardHeader>
                <CardHeading>Rahmenvereinbarungen</CardHeading>
                <HasPermission resource="masterData.agreements" permission="create">
                    <CardActions>
                        <PlusButton {...plusButtonProps} />
                    </CardActions>
                </HasPermission>
            </CardHeader>
            <CardContent>
                <DataTable
                    activeRow={'0'}
                    onClick={({ original }) => setActiveGeneralAgreement(original)}
                    {...tableProps}
                />
            </CardContent>
        </Card>
    )
}
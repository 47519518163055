import { camelCase } from 'change-case';
import { flatMap } from 'lodash';
import { EinsatzberichtDetail } from '~/gql/ucpw/graphql';
import log from '~/log';

const PRESSURE_TEST_FIELDS = [
    'druckpruefung_massnahme',
    'kaltwasser_druckpruefung_massnahme',
    'druckverlust_kaltwasser_druckpruefung',
    'warmwasser_druckpruefung_massnahme',
    'druckverlust_warmwasser_druckpruefung',
    'heizung_druckpruefung_massnahme',
    'druckverlust_heizung_druckpruefung',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const graphQlToOperationalReportOptions =
    (form = [], options = []) =>
    (data: EinsatzberichtDetail[] = []) => {
        const dataValues: any = data.reduce((acc, value) => {
            const { einsatzberichtOptionId, jaNein, anzahl, text } = value;
            const option = {
                optionId: einsatzberichtOptionId,
                checked: jaNein,
                count: anzahl,
                text,
            };
            return { ...acc, [value?.einsatzberichtOption?.bezeichnung as string]: option };
        }, {});

        log.debug('graphQlToOperationalReportOptions', { dataValues, form });

        const values = form.reduce((acc: any, field: any) => {
            const fieldName = camelCase(field?.path);
            const ui = typeof field?.ui === 'function' ? field?.ui() : field?.ui;
            const fieldType = ui?.type;
            const component = ui?.component;
            const fieldValue = dataValues[field?.path] || {};
            const isNumber = fieldType === 'number';

            console.log('graphQlToOperationalReportOptions.map', {
                fieldName,
                fieldValue,
                component,
            });

            switch (component) {
                case 'Radiobox': {
                    const componentValue = (ui as any)?.props?.options?.reduce(
                        (acc: any, option: any) => ({
                            ...acc,
                            [option.name]: dataValues[option.name]?.checked,
                        }),
                        {}
                    );
                    log.debug(
                        `graphQlToOperationalReportOptions.Radiobox ${fieldName}`,
                        fieldName,
                        componentValue
                    );
                    return {
                        ...acc,
                        [fieldName]: componentValue,
                    };
                }
                case 'Checkbox': {
                    return { ...acc, [fieldName]: fieldValue.checked };
                }
                case 'CheckboxControl': {
                    return { ...acc, [fieldName]: fieldValue.checked };
                }
                case 'InputOnDemand': {
                    const componentValue = {
                        checked: fieldValue.checked,
                        input: isNumber ? fieldValue.anzahl : fieldValue.text,
                    };
                    log.debug(
                        `graphQlToOperationalReportOptions.InputOnDemand ${fieldName}`,
                        fieldName,
                        componentValue
                    );
                    return {
                        ...acc,
                        [fieldName]: componentValue,
                    };
                }
                case 'PressureTest': {
                    const componentValue = PRESSURE_TEST_FIELDS.reduce(
                        (acc, name) => ({ ...acc, [name]: dataValues[name]?.checked }),
                        {}
                    );
                    log.debug(
                        `graphQlToOperationalReportOptions.PressureTest ${fieldName}`,
                        fieldName,
                        componentValue
                    );
                    return {
                        ...acc,
                        [fieldName]: componentValue,
                    };
                }
                case 'Dependants': {
                    const componentValue = (ui as any)?.props?.options?.reduce(
                        (acc: any, option: any) => ({
                            ...acc,
                            [option.name]: { isChecked: dataValues[option.name]?.checked },
                        }),
                        {}
                    );
                    log.debug(
                        `graphQlToOperationalReportOptions.Dependants ${fieldName}`,
                        fieldName,
                        componentValue
                    );
                    return {
                        ...acc,
                        [fieldName]: componentValue,
                    };
                }
                case 'FollowUp': {
                    const componentValue = {
                        isChecked: fieldValue.checked,
                        isClarification: dataValues[ui?.props?.clarification?.name]?.checked,
                    };
                    log.debug(
                        `graphQlToOperationalReportOptions.FollowUp ${fieldName}`,
                        fieldName,
                        componentValue
                    );
                    return {
                        ...acc,
                        [fieldName]: componentValue,
                    };
                }
                case 'PhotoDocumentation': {
                    try {
                        return { ...acc, [fieldName]: JSON.parse(fieldValue?.text || '[]') };
                    } catch (error) {
                        console.error(error, fieldValue);
                        return acc;
                    }
                }
                case 'MeterReadings': {
                    try {
                        return { ...acc, [fieldName]: JSON.parse(fieldValue?.text || '[]') };
                    } catch (error) {
                        console.error(error, fieldValue);
                        return acc;
                    }
                }
                case 'RichTextEditor': {
                    try {
                        return { ...acc, [fieldName]: JSON.parse(fieldValue?.text || '[]') };
                    } catch (error) {
                        console.error(error, fieldValue);
                        return acc;
                    }
                }
                default: {
                    return acc;
                }
            }
        }, {});

        log.debug('graphQlToOperationalReportOptions.values', values);

        return values;
    };

export const operationalReportOptionsToGraphQL =
    (form = [], options = []) =>
    (data = [], defaultValues: any = {}, fixedValues: any = {}) => {
        const { reportId } = fixedValues;

        if (!options.length) {
            return [];
        }

        const lookup = options.reduce(
            (acc: any, option: any) => ({
                ...acc,
                byId: { ...acc.byId, [option?.id as string]: option?.bezeichnung },
                byName: { ...acc.byName, [option.bezeichnung]: option.id },
            }),
            { byId: {}, byName: {} }
        );
        console.log('onSubmit.upsert.lookup', options, lookup);
        log.debug('reportOptionsToGraphQL', { data, defaultValues, fixedValues, lookup });

        const optionId = (name: string) => lookup?.byName[name];

        const optionValues = flatMap(form, (field: any) => {
            const fieldName = field.path;
            const ui = typeof field?.ui === 'function' ? field?.ui?.() : field?.ui;
            const component = ui?.component;
            const fieldValue: any = data[fieldName] === undefined ? {} : data[fieldName];

            const fieldType = ui?.type;
            const isNumber = fieldType === 'number';
            const isText = !isNumber;

            log.debug(
                'reportOptionsToGraphQL.mapValue',
                component,
                fieldName,
                optionId(fieldName),
                fieldValue
            );

            switch (component) {
                case 'Radiobox': {
                    return (ui as any)?.props?.options?.map(({ name }: any) => ({
                        bezeichnung: name,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(name),
                        jaNein: fieldValue?.[name],
                    }));
                }
                case 'CheckboxControl': {
                    return {
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(field.path),
                        jaNein: fieldValue,
                    };
                }
                case 'InputOnDemand': {
                    console.log(`OperationalReportForm.reset.${field.path}`, {
                        data,
                        fieldValue,
                        fieldName,
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        jaNein: fieldValue?.checked,
                        einsatzberichtOptionId: optionId(field.path),
                        ...(isText && { text: fieldValue?.input }),
                        ...(isNumber && { anzahl: fieldValue?.input }),
                    });
                    return {
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        jaNein: fieldValue?.checked,
                        einsatzberichtOptionId: optionId(field.path),
                        ...(isText && { text: fieldValue?.input }),
                        ...(isNumber && { anzahl: fieldValue?.input }),
                    };
                }
                case 'PressureTest': {
                    return PRESSURE_TEST_FIELDS.map((name) => ({
                        bezeichnung: name,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(name),
                        jaNein: fieldValue?.[name]?.jaNein,
                    }));
                }
                case 'Dependants': {
                    return (ui as any)?.props?.options?.map(({ name }: any) => ({
                        bezeichnung: name,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(name),
                        ...(typeof fieldValue?.[name]?.isChecked === 'boolean' && {
                            jaNein: fieldValue?.[name]?.isChecked,
                        }),
                    }));
                }
                case 'FollowUp': {
                    const data = [
                        {
                            bezeichnung: field.path,
                            einsatzberichtId: reportId,
                            einsatzberichtOptionId: optionId(field.path),
                            jaNein: fieldValue?.isChecked,
                            ...(fieldValue?.trade && {
                                text: JSON.stringify(fieldValue?.trade),
                                anzahl: fieldValue?.trade?.value,
                            }),
                        },
                        {
                            bezeichnung: ui?.props?.clarification?.name,
                            einsatzberichtId: reportId,
                            einsatzberichtOptionId: optionId(ui?.props?.clarification?.name),
                            ...(fieldValue?.isClarification && {
                                jaNein: fieldValue?.isClarification?.jaNein,
                            }),
                        },
                    ];
                    console.log('onSubmit.FollowUp', {
                        [component]: data,
                    });
                    return data;
                }
                case 'PhotoDocumentation': {
                    return {
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(field.path),
                        text: JSON.stringify(fieldValue),
                    };
                }
                case 'MeterReadings': {
                    console.log('MeterReadings.FIXME', fieldValue);
                    return {
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(field.path),
                        // don't stringify to often
                        text: fieldValue?.text ? fieldValue?.text : JSON.stringify(fieldValue),
                    };
                }
                case 'RichTextEditor': {
                    console.log('onSubmit.RichTextEditor', fieldValue, typeof fieldValue);
                    return {
                        bezeichnung: field.path,
                        einsatzberichtId: reportId,
                        einsatzberichtOptionId: optionId(field.path),
                        text: fieldValue,
                    };
                }
                default: {
                    return undefined;
                }
            }
        }).filter(Boolean);

        log.debug('operationalReportOptionsToGraphQL', { optionValues });

        return optionValues;
    };

export const printCreateMutation = (options: any = []) => {
    console.log('printMutation', options);

    return `
        mutation createOptions {
            ${options.map(
                (option: any) => `
                ${camelCase(option.bezeichnung)}: createEinsatzberichtDetail(
                    data: {einsatzberichtId: ${option.einsatzberichtId}, einsatzberichtOptionId: ${
                    option.einsatzberichtOptionId
                }, jaNein: ${Boolean(option.jaNein)}, anzahl: ${
                    option.anzahl ? option.anzahl : null
                }, text: "${option.text ? option.text.replaceAll('"', '\\"') : ''}"}
                ) {
                    item {
                        id
                        einsatzberichtId
                        einsatzberichtOptionId
                        einsatzberichtOption {
                            bezeichnung
                        }
                        jaNein
                        anzahl
                        text
                    }
                }
            `
            )}
        }
    `;
};

export const printCreateOrUpdateMutation = (details = []) => {
    console.log('printMutation', details);

    return `
        mutation createOptions {
            ${details.map((detail: any) =>
                detail.id
                    ? `
                ${camelCase(detail.bezeichnung)}: updateEinsatzberichtDetail(
                    id: ${detail.id},
                    data: {einsatzberichtId: ${detail.einsatzberichtId}, einsatzberichtOptionId: ${
                          detail.einsatzberichtOptionId
                      }, jaNein: ${Boolean(detail.jaNein)}, anzahl: ${
                          detail.anzahl ? detail.anzahl : null
                      }, text: "${detail.text ? detail.text.replaceAll('"', '\\"') : ''}"}
                ) {
                    item {
                        id
                        einsatzberichtId
                        einsatzberichtOptionId
                        einsatzberichtOption {
                            bezeichnung
                        }
                        jaNein
                        anzahl
                        text
                    }
                }
            `
                    : `
                    ${camelCase(detail.bezeichnung)}: createEinsatzberichtDetail(
                        data: {einsatzberichtId: ${
                            detail.einsatzberichtId
                        }, einsatzberichtOptionId: ${
                          detail.einsatzberichtOptionId
                      }, jaNein: ${Boolean(detail.jaNein)}, anzahl: ${
                          detail.anzahl ? detail.anzahl : null
                      }, text: "${detail.text ? detail.text.replaceAll('"', '\\"') : ''}"}
                    ) {
                        item {
                            id
                            einsatzberichtId
                            einsatzberichtOptionId
                            einsatzberichtOption {
                                bezeichnung
                            }
                            jaNein
                            anzahl
                            text
                        }
                    }
                `
            )}
        }
    `;
};

import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useModal } from '~/hooks/useModal';
import { useDataLoader } from '~/hooks/useDataLoader';
import { tables as openTasksTable } from '../meta/data/open-tasks.schema';
import { tables as taskTables } from '../meta/data/task.schema';
import {
    ListTasksDocument,
    CreateTaskDocument,
    UpdateTaskDocument,
    CreateTaskFunctionDocument,
    UpdateTaskFunctionDocument,
    DeleteTaskFunctionDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils/withRenderers';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { useContextAndRefetchQueries, usePermission } from '~/hooks';

export const useOpenTasks = ({ tableName = 'open-task', filter = {} }: any = {}) => {
    const { canView, canEdit } = usePermission('project.tasks');
    const tables = {
        'open-task': openTasksTable,
        task: taskTables,
    };

    const orderBy = filter?.zuErledigenNotInFuture
        ? [{ angelegt: SortOrder.Desc }]
        : [{ zuErledigen: SortOrder.Asc }];

    const navigate = useNavigate();
    const contextAndRefetchQueries = useContextAndRefetchQueries(ListTasksDocument, {
        filter,
        orderBy,
    });

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch, startPolling, stopPolling }] = useLazyQuery(
        ListTasksDocument,
        {
            context: contextAndRefetchQueries.context,
            fetchPolicy: 'network-only',
            // pollInterval: 500,
        }
    );
    const tasks: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData } = useDataLoader(load);

    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        filter,
        orderBy,
    });

    // TODO: evaluate if we need polling in future cases - UCPW-306
    // React.useEffect(() => {
    //     if (tasks.length) startPolling(500);
    //     return (): void => stopPolling();
    // }, [tasks, filter]);

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateTaskDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateTaskDocument,
        contextAndRefetchQueries
    );

    const [createTaskFunction, { loading: createTaskFunctionLoading }] = useMutation(
        CreateTaskFunctionDocument,
        contextAndRefetchQueries
    );

    const [updateTaskFunction, { loading: updateTaskFunctionLoading }] = useMutation(
        UpdateTaskFunctionDocument,
        contextAndRefetchQueries
    );

    const [deleteTaskFunction, { loading: deleteTaskFunctionLoading }] = useMutation(
        DeleteTaskFunctionDocument,
        contextAndRefetchQueries
    );

    const loading =
        lazyLoading ||
        createLoading ||
        updateLoading ||
        createTaskFunctionLoading ||
        updateTaskFunctionLoading ||
        deleteTaskFunctionLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // CONTROLS
    // ===============================================

    const setTaskModal = () =>
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'TaskModal',
                props: {
                    createMutation,
                    updateMutation,
                    createTaskFunction,
                    updateTaskFunction,
                    deleteTaskFunction,
                    refetch,
                    loading,
                },
            },
        });

    const onEdit = React.useCallback(
        (row: any) => {
            setTaskModal();
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    const onClick = (row: any = {}) => {
        const state = row?.original || {};
        navigate(
            `/projekte/${row?.original?.subprojekt?.projekt?.id}/${row?.original?.subprojekt?.id}/details`,
            {
                state,
            }
        );
    };

    const controls = [
        canView && {
            title: 'zur Projektübersicht',
            props: { onClick },
            enabled: () => canView,
        },
        {
            title: 'Bearbeiten',
            props: {
                onClick: (row: any) => {
                    setTaskModal();
                    dispatch?.({ type: 'setRow', data: { row } });
                    onOpen?.();
                },
            },
            enabled: () => canEdit,
        },
    ].filter(Boolean);

    // ===============================================
    // TABLE
    // ===============================================
    const table = tables?.[tableName as keyof typeof tables];
    const columns = React.useMemo(() => withRenderers(table.columns as any, controls), [controls]);

    console.log('OpenTask.colums', columns);

    const hiddenColumns = table?.hiddenColumns;
    const pageSize = table?.pageSize;

    return {
        data: tasks,
        fetchData: Object.values(filter).length ? fetchDataWithFilters : fetchData,
        pageCount,
        loading,
        columns,
        hiddenColumns,
        onEdit,
        onClick,
        pageSize,
    };
};

import React from "react";
import * as yup from 'yup'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { BiCalendarEvent } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { useMutation, useQuery } from "@apollo/client";
import { format, startOfDay, endOfDay, addMinutes } from 'date-fns'
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid, ListItem, ListIcon, useBoolean, GridItem, Alert, AlertIcon, List } from '@chakra-ui/react';
import { ListEmployeesDocument, SortOrder, ListAbsencesDocument, ListEventsDocument, Termin, CreateAbsenceDocument, UpdateAbsenceDocument, DeleteAbsenceDocument } from "~/gql/ucpw/graphql";
import { fields, forms } from "~/pages/staffing/meta/data/absences.schema";
import { useSecurity } from "@ucc/react/security";
import { Form, renderField } from "~/components/Form/Form";
import { HasPermission } from "~/layout/HasPermission";
import { usePermission, useDebounce, useAbsenceOverlap, useYupValidationResolver, useDeleteWithConfirmation, useContextAndRefetchQueries, useModal } from "~/hooks";
import { resolveFormFields, join } from "~/utils";
import log from '~/log'

export const AbsenceModal = (props: any) => {
    const { canEdit } = usePermission('staff.absences')
    const { viewer } = useSecurity();
    const employeeId = viewer?.app?.mitarbeiterId;

    const { createMutation, updateMutation, loading: isLoading = false } = props
    const { state, dispatch, onClose } = useModal();
    const { row: absence = {} } = state?.rows || {};

    const absenceId = absence?.abwesenheitId;
    const formFields = resolveFormFields(forms.absences, fields.absences);
    const id = useDebounce(absenceId, 200)

    const checkAbsenceOverlapping = useAbsenceOverlap()

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(absence),
        employeeOriginatorId: { value: employeeId },
    };

    const { handleSubmit, control, watch, register, clearErrors, setError, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(
            yup.object({
                ...formFields.rules,
                dateFrom: yup
                    .string()
                    .test(
                        'dateFrom',
                        '"Datum von" muss kleiner gleich "Datum bis" sein',
                        (value = '', { parent }) => {
                            return value <= parent.dateUntil
                        }
                    ),
                dateUntil: yup
                    .string()
                    .test(
                        'dateUntil',
                        '"Datum bis" muss größer gleich "Datum von" sein',
                        (value = '', { parent }) => {
                            return value >= parent.dateFrom
                        }
                    ),
            })),
    });



    const { dateFrom, dateUntil } = watch()

    const { data } = useQuery(ListEventsDocument, {
        context: { clientName: 'ucpw' },
        variables: {
            filter: {
                mitarbeiterIdSachbearbeiter: absence?.mitarbeiterId,
                geplantFrom: startOfDay(new Date(dateFrom)),
                geplantTo: endOfDay(new Date(dateUntil)),
            }
        }
    });
    const events = data?.items?.items || [];
    const hasEventOverlap = Boolean(events.length)

    React.useEffect(() => {
        const checkOverlap = async () => {
            const { regardingFields }: any = await checkAbsenceOverlapping({
                dateFrom,
                dateUntil,
                employeeId,
                id: absenceId
            })
            clearErrors(regardingFields)
        }

        checkOverlap().catch(console.error)

        if (dateFrom <= dateUntil && (errors?.dateUntil || errors?.dateFrom)) {
            clearErrors(['dateFrom', 'dateUntil'])
        }

    }, [dateFrom, dateUntil, absenceId, errors])

    const onSubmit = React.useCallback(async (values: any) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const { mitarbeiterIdUrheber, ...data } = formFields.toGql(values, {}) as any;
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = id
            ? await updateMutation({ variables: { id, data } })
            : await createMutation({ variables: { data } });
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    }, [id])

    const onSubmitWithOnClose = React.useCallback(async (values: any) => {
        const { hasOverlap, regardingFields }: any = await checkAbsenceOverlapping({
            dateFrom: values?.dateFrom,
            dateUntil: values?.dateUntil,
            employeeId: absence?.mitarbeiterId,
            id: absenceId
        })
        console.log('onSubmit.regardingFields', regardingFields)
        if (hasOverlap) {
            const map = {
                dateFrom: '"Datum von" hat eine Abwesenheitsüberschneidung',
                dateUntil: '"Datum bis" hat eine Abwesenheitsüberschneidung'
            }
            type FieldType = keyof typeof map
            return regardingFields.forEach((field: FieldType) => setError(field, { message: map?.[field] }))
        }
        await onSubmit(values);
        onReset();
    }, [absence?.mitarbeiterId, absenceId]);


    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return <ModalContent maxWidth="container.md" rounded="none" >
        <ModalHeader
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            borderBottomWidth={1}
            borderColor="gray.200"
            mb={6}
            p={5}>

            {id ? `Abwesenheit ${canEdit ? 'bearbeiten' : 'ansehen'}` : 'Neue Abwesenheit'}
            <HStack>
                <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                    Abbrechen
                </Button>
                <HasPermission resource="staff.absences" permission="update">
                    <Button
                        key={`isDisabled.${hasEventOverlap}`}
                        isLoading={isLoading}
                        data-test-id="button-save-insurance"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                        isDisabled={hasEventOverlap}
                    >
                        Speichern
                    </Button>
                </HasPermission>
            </HStack>
        </ModalHeader>
        <ModalBody pointerEvents={canEdit ? 'auto' : 'none'}>
            <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                <SimpleGrid spacing={4} columns={2} mb={6}>
                    {formFields.fields.map((field: any) => (
                        <React.Fragment key={field.name}>
                            {renderField({
                                field,
                                control,
                                register,
                                errors,
                                context: {},
                            })}
                        </React.Fragment>
                    ))}
                    {hasEventOverlap &&
                        <>
                            <GridItem colSpan={2}>
                                <Alert status="warning">
                                    <AlertIcon />
                                    Es kommt zu einer terminlichen Zeitüberschneidung
                                </Alert>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <List spacing={3} pl={4}>
                                    {events.map((props: any) => <EventListItem key={props?.id} {...props} />)}
                                </List>
                            </GridItem>
                        </>}
                </SimpleGrid>
            </Form>
        </ModalBody>
    </ModalContent>
}

const EventListItem = (props: Termin) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useBoolean();
    const { projektId } = props?.subprojekt || {};
    const { plz, ort, strasse, niederlassungId } = props?.subprojekt?.projekt || {};
    const state = {
        projectId: projektId,
        subprojectId: props.subprojektId,
        branchId: niederlassungId,
        termin: props,
        ...props
    };
    return <ListItem
        cursor="pointer"
        _hover={{ color: 'blue.500' }}
        onMouseEnter={setIsHovered.on}
        onMouseOut={setIsHovered.off}
        onClick={() => navigate(`/projekte/termine/${format(new Date(props.geplant), 'yyyy-MM-dd')}`, {
            state,
        })}>
        <ListIcon
            as={isHovered ? FiExternalLink : BiCalendarEvent}
            pointerEvents="none"
            color={
                addMinutes(
                    new Date(props.geplant),
                    props.dauer
                ) < new Date() && !props?.erledigt && !isHovered
                    ? 'red.500'
                    : 'blue.500'
            } />
        {join([join([strasse, join([plz, ort], ' ')]), format(new Date(props.geplant), 'dd.MM.yyyy HH:mm')], ' - ')}
    </ListItem>
}

export const useAbsences = (args: any = {}) => {
    console.log('useStaff', args)
    const { branchId, employeeFunctionId, fromDate, untilDate } = args;

    // ===============================================
    // LISTING EMPLOYEES
    // ===============================================

    const context = { clientName: 'ucpw' }

    const employeesVariables = {
        filter: {
            ...(branchId && { niederlassungId: branchId }),
            ...(employeeFunctionId && {
                mitarbeiterFunktion: {
                    funktionId: employeeFunctionId
                }
            })
        },
        limit: 1000,
        orderBy: [{ name: SortOrder.Asc }]
    }

    const { data: listData, loading: listLoading } = useQuery(ListEmployeesDocument, {
        variables: employeesVariables,
        context,
    });
    const staff = listData?.items?.items || [];
    const staffIds = staff.reduce(
        (acc: any, { id: employeeId, name, vorname, urlaubstage = 0 }: any, idx: number) => ({
            ...acc,
            [employeeId]: {
                idx,
                employeeId,
                displayName: [name, vorname].filter(Boolean).join(', '),
                urlaubstage,
                absences: [],
                vacations: [],
                vacationDaysTaken: 0,
            },
        }),
        {}
    );

    const { refetchQueries: employeeRefetchQueries } = useContextAndRefetchQueries(ListEmployeesDocument, employeesVariables);

    // ===============================================
    // LISTING ABSENCES
    // ===============================================

    const absencesVariables = {
        limit: 1000,
        orderBy: { datumFrom: SortOrder.Asc },
        filter: {
            datumFromTo: format(untilDate, 'yyyy-MM-dd'),
            datumUntilFrom: format(fromDate, 'yyyy-MM-dd'),
            // ⚠️ This stays here as an reminder, absence didn't showed up this way
            // datumFromFrom: format(fromDate, 'yyyy-MM-dd'), // "2024-03-01"
            // datumUntilTo: format(untilDate, 'yyyy-MM-dd'), // "2024-03-31"
            ...((branchId || employeeFunctionId) && {
                mitarbeiter: {
                    ...(branchId && { niederlassungId: branchId }),
                    ...(employeeFunctionId && {
                        mitarbeiterFunktion: {
                            funktionId: employeeFunctionId
                        }
                    })
                }
            }),
        },
    };

    const { data: listAbsenceData, loading: listAbsenceLoading } = useQuery(ListAbsencesDocument, {
        variables: absencesVariables,
        context,
    });
    const absences = listAbsenceData?.items?.items || [];

    const { refetchQueries: absencesRefetchQueries } = useContextAndRefetchQueries(ListAbsencesDocument, absencesVariables);

    absences.forEach((absence: any) => {
        staffIds?.[absence.mitarbeiterId]?.absences.push(absence);
    });

    const schedule = React.useMemo(() => Object.values(staffIds || {})?.sort?.((a: any, b: any) => a?.idx - b?.idx), [staffIds]);

    console.log('useAbsence.SCHEDULE', { staffIds, schedule, absencesVariables, absences })

    const options = {
        context,
        refetchQueries: [
            ...employeeRefetchQueries,
            ...absencesRefetchQueries
        ]
    }

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(CreateAbsenceDocument, options);
    const [updateMutation, { loading: updateLoading }] = useMutation(UpdateAbsenceDocument, options);
    const [deleteMutation, { loading: deleteLoading }] = useMutation(DeleteAbsenceDocument, options);

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: options.refetchQueries,
        dependencies: [deleteMutation, options.refetchQueries]
    });

    const loading = listLoading || listAbsenceLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal(<AbsenceModal {...{ createMutation, updateMutation }} />);


    const onEdit = (data: any) => {
        dispatch?.({
            type: 'setRow', data: {
                row: {
                    original: data
                }
            },
        });
        onOpen?.();
    }


    return {
        schedule,
        loading,
        onEdit,
        onDelete: deleteWithConfirmation,
    }
}
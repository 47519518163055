import { create } from 'zustand';

type SearchStore = {
    value: string;
    prefix: string | string[];
    filter: Record<string, string | number>;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPrefixChange: (arg: string | string[]) => void;
};

const getFilter = ({ value, prefix }: SearchStore) =>
    value && prefix ? { [Array.isArray(prefix) ? prefix[0] : prefix]: '%' + value } : {};

export const useSearchFilter = create<SearchStore>((set) => ({
    value: '',
    prefix: '',
    filter: {},
    onChange: (e) =>
        set((state) => {
            const value = e?.target?.value || '';
            return {
                value,
                filter: getFilter({ ...state, value }),
            };
        }),
    onPrefixChange: (prefix) =>
        set((state) => ({ prefix, filter: getFilter({ ...state, prefix }) })),
}));

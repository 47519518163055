import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { omit } from 'lodash';
import * as yup from 'yup';
import { Button, HStack, ModalBody, ModalContent, ModalHeader, SimpleGrid } from '@chakra-ui/react';
import { Form, renderField } from '~/components/Form/Form';
import { useModal } from '~/hooks/useModal';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { fields, forms } from '~/pages/projects/meta/data/finance.schema';
import { resolveFormFields } from '~/utils';
import { FinanceCalculationProps } from './FinanceCalculation';
import { usePermission } from '~/hooks';
import log from '~/log';

export const FreePositionModalContent = ({
    send,
    ...props
}: FinanceCalculationProps & { id: string }) => {
    const { onClose, dispatch } = useModal();
    const { type = 'calculation', cache = {}, calculations = {}, subprojectId, projectId } = props?.context || {}
    const { row: position = {}, rowId: positionId, invoiceId, offerId } = cache;
    const formFields = resolveFormFields(forms.freePosition, fields.position);
    const { highestPosition = 0 } = type === 'calculation' ? calculations : cache;

    const positionValues: any = formFields.toForm(position);
    const defaultValues = {
        ...formFields.defaultValues,
        ...positionValues,
        position: positionValues?.position || highestPosition + 1,
    };
    const context = { positionId, projectId, subprojectId };
    const permissions = usePermission(`finance.${type}`)
    const { canEdit, canCreate } = permissions
    const canSave = positionId && canEdit ? true : !positionId && canCreate ? true : false

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    React.useEffect(() => {
        reset(defaultValues);
    }, [highestPosition, positionId]);

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', values);
        const fixedValues = { subprojectId: subprojectId };
        log.debug('onSubmit.fixedValues', fixedValues);
        const data: any = formFields.toGql(values?.generalAgreementPositionId ? values : omit(values, 'generalAgreementPositionId'), defaultValues, fixedValues);
        log.debug('onSubmit.data', data);
        const submitData =
            type === 'offer'
                ? { ...data, angebotId: offerId }
                : type === 'invoice'
                    ? { ...data, rechnungId: invoiceId }
                    : { ...data, subprojektId: subprojectId }; // calculation
        log.debug('onSubmit.submitData', submitData);
        const response = positionId
            ? send?.({ type: 'UPDATE_POSITION', variables: { id: positionId, data: submitData } })
            : send?.({ type: 'CREATE_POSITION', variables: { data: submitData } });
        log.debug('onSubmit.response', response);
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {positionId ? `Freie Position bearbeiten` : `Neue freie Position`}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {canSave ? 'Abbrechen' : 'Schließen'}
                    </Button>
                    {canSave && <Button
                        data-test-id="button-save"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>}
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors, context })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

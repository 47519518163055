import { tableColumnMapper } from '~/utils/tableColumnMapper';

const columns = {
    __graphqlType: 'Notdienstprojekt',
    title: { header: 'Titel', accessor: 'name', enableSorting: false },
    address: { header: 'Adresse', accessor: 'address',  enableSorting: false },
    notes: { header: 'Notiz', accessor: 'notes',  enableSorting: false },
    responsible: { header: 'Verantwortlich', accessor: 'email', renderer: { name: 'EmergencyResponsible' },  enableSorting: false },
    createdAt: { header: 'Timestamp', accessor: 'createdAt', renderer: { name: 'EmergencyTimestamp' },  enableSorting: false },
    status: { header: 'Status', accessor: 'status',  enableSorting: false },
    controls: {
        accessor: 'controls',
        width: 60,
        renderer: { name: 'Controls' },
        enableSorting: false,
    },
};

export const table = {
    columns: ['title', 'address', 'notes', 'responsible', 'createdAt', 'status', 'controls'].map(
        tableColumnMapper(columns)
    )
};


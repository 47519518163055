import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Box, VStack } from '@chakra-ui/react';
import {
    DataTable,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardHeading,
    PlusButton,
} from '@ucc/react/ui';
import { useModal } from '~/hooks';
import { MultiModal } from '~/components/MultiModal';
import {
    combineReducers,
    contactReducer,
    formStateReducer,
    modalReducer,
    rowReducer,
} from '~/reducers';
import { useMeasure } from '~/pages/projects/hooks/useMeasure';
import { useMeasureRemark } from '~/pages/projects/hooks/useMeasureRemark';
import { ContactSearchModalContent } from './ContactSearchModalContent';
import { CreateContactModalContent } from './CreateContactModalContent';
import { ResidentialUnitsModalContent } from './ResidentialUnitsModal';
import { Remark } from '~/pages/projects/components/Remark';
import { OverrideActions } from '~/components/Layout/PageHeader';
import { ProjectIds } from '../types';
import { ResidentialUnit, ResidentialUnitMeasureModal } from './ResidentialUnitMeasureModal';
import { HasPermission } from '~/layout/HasPermission';

type ResidentialUnitMeasureProps = {
    unit: ResidentialUnit & { wattroProjectExists: boolean };
} & ProjectIds;

export function Measure() {
    const params = useParams();
    const projectId = parseInt(params?.projectId || '', 10);
    const subprojectId = parseInt(params?.subprojectId || '', 10);
    const { subprojectResidentialUnits, project, createResidentialUnit, participantTypeSnippetId } =
        useMeasure({ projectId, subprojectId });

    const { remark, updateRemark } = useMeasureRemark({ projectId, subprojectId });

    const { onOpen, dispatch } = useModal({
        defaultState: { modals: { activeModal: 'ResidentialUnits' } },
        dependencies: [createResidentialUnit, participantTypeSnippetId],
        component: (
            <MultiModal>
                <ResidentialUnitsModalContent
                    id="ResidentialUnits"
                    createMutation={createResidentialUnit}
                    key={participantTypeSnippetId}
                    participantTypeSnippetId={participantTypeSnippetId}
                />
                <ResidentialUnitMeasureModal id="ResidentialUnitMeasure" />
                <ContactSearchModalContent id="SearchContact" />
                <CreateContactModalContent id="CreateContact" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('ResidentialUnits'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
            row: rowReducer,
        }),
    });

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && Object.keys(project).length) {
            dispatch?.({
                type: 'setDefaultAddress',
                data: {
                    defaultAddress: {
                        street: project?.strasse,
                        zipCode: project?.plz,
                        location: project?.ort,
                    },
                },
            });
        }
        return () => {
            isMounted = false;
        };
    }, [projectId, subprojectId, project, location?.pathname]);

    return (
        <>
            <OverrideActions>
                <HasPermission resource="project.residentialUnits" permission="create">
                    <PlusButton
                        data-test-id="button-new-residentialUnit"
                        onClick={() => {
                            dispatch?.({
                                type: 'setModal',
                                data: {
                                    modal: 'ResidentialUnits',
                                },
                            });
                            onOpen?.();
                        }}
                    >
                        Neue Wohneinheit
                    </PlusButton>
                </HasPermission>
            </OverrideActions>
            <VStack spacing={6} p={6}>
                {subprojectResidentialUnits.map((unit: any) => (
                    <ResidentialUnitMeasure
                        key={unit.id}
                        projectId={projectId}
                        subprojectId={subprojectId}
                        unit={unit}
                    />
                ))}
                <Card>
                    <CardHeader borderBottomWidth={1} borderColor="gray.200">
                        <CardHeading>Bemerkungen</CardHeading>
                    </CardHeader>
                    <Box p={5}>
                        <Remark
                            defaultValue={remark}
                            onSave={updateRemark}
                            onDelete={updateRemark}
                        />
                    </Box>
                </Card>
            </VStack>
        </>
    );
}

const ResidentialUnitMeasure = ({ unit, projectId, subprojectId }: ResidentialUnitMeasureProps) => {
    const { columns, hiddenColumns, measurements, loading, onCreate, pageSize, onClick } =
        useMeasure({
            projectId,
            subprojectId,
            residentialUnitId: unit.id,
        });

    return (
        <Card key={unit.id} boxShadow="none" data-test-id={`measure-card-${unit.id}`}>
            <CardHeader>
                <CardHeading>{unit.title}</CardHeading>
                <CardActions>
                    <HasPermission resource="project.measures" permission="create">
                        <PlusButton
                            data-test-id={`button-new-measure-for-${unit.id}`}
                            onClick={() => {
                                onCreate?.({
                                    unit: {
                                        ...unit,
                                        title: 'Neue Position',
                                    },
                                });
                            }}
                        >
                            Position hinzufügen
                        </PlusButton>
                    </HasPermission>
                </CardActions>
            </CardHeader>
            <CardContent>
                <DataTable
                    pageSize={pageSize}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    data={measurements}
                    loading={loading}
                    onClick={(row) =>
                        onClick?.(row, {
                            unit: {
                                ...unit,
                                title: 'Position bearbeiten',
                            },
                        })
                    }
                />
            </CardContent>
        </Card>
    );
};

import React, { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { format, subMonths } from 'date-fns';
import { pick } from 'lodash';
import * as yup from 'yup';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import {
    ModalBody,
    ModalContent,
    ModalHeader,
    HStack,
    SimpleGrid,
    InputGroup,
    InputLeftElement,
    Button,
    Input,
    GridItem,
    Alert,
    AlertIcon,
    Tooltip,
} from '@chakra-ui/react';
import { useSecurity } from '@ucc/react/security';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDebounce } from '~/hooks/useDebounce';
import { Form, renderField } from '~/components/Form/Form';
import { useModal } from '~/hooks/useModal';
import { resolveFormFields } from '~/utils';
import {
    fields as eventFields,
    forms as eventForms,
} from '~/pages/projects/meta/data/events.schema';
import { CalendarProps } from './Calendar';
import { DataTable } from '@ucc/react/ui';
import { join, getClient } from '~/utils';
import { tables } from '~/pages/projects/meta/data/projects.schema';
import * as renderers from './Renderers';
import { useSearchProject } from '~/pages/projects/ui/SearchProject/SearchProject';
import { generateProjectNumber } from '~/utils';
import { usePermission } from '~/hooks';
import { useOverlap } from './hooks';
import { HasPermission } from '~/layout/HasPermission';
import log from '~/log';
import { useDialog } from '~/hooks/useDialog';

export const Modals = {
    ProjectSearch: 'ProjectSearch',
    CreateEvent: 'CreateEvent',
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CreateEventModalContent = (props?: CalendarProps & any) => {
    const [isAufbau, setIsAufbau] = useState(false)
    const { viewer } = useSecurity();
    const employeeId = viewer?.app?.mitarbeiterId;

    const { dispatch, state, onClose, onOpen } = useModal();
    const dialog = useDialog()
    const { formState = {}, persistFormState = {} } = state;
    const event = { ...persistFormState, ...formState };
    const eventId = event?.id;

    log.debug('CreateEventModalContent!',
        pick(state, 'formState', 'persistFormState'),
        pick(props, 'onCreateEvent', 'onUpdateEvent', 'branchId')
    );
    const { onCreateEvent } = state?.modals?.props || props || {}
    const onUpdateEvent = state?.modals?.props ? state?.modals?.props?.onUpdateEvent : props?.onUpdateEvent ? props?.onUpdateEvent : event?.updateEventMutation;

    const formFields = resolveFormFields(eventForms.events, eventFields.events);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(event),
        ...(event?.employeeId && { employeeId: event?.employeeId }),
        ...(event?.typeSnippetId && { typeSnippetId: event?.typeSnippetId }),

    };
    const { control, register, handleSubmit, formState: { errors }, getValues, watch } = useForm({
        defaultValues,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const { canCreate, canEdit, canView } = usePermission('project.schedule');
    const readOnly = eventId && canView && !(canCreate || canEdit);
    const modalTitle = canEdit || canCreate ? event?.title : 'Termin ansehen';
    const cancelButtonName = modalTitle === 'Termin ansehen' ? 'Schließen' : 'Abbrechen';
    const buttonIsClose = modalTitle === 'Termin ansehen';
    const permssionType = eventId ? 'update' : 'create';
    const cursor = buttonIsClose ? 'not-allowed' : 'auto';
    const pointerEvents = buttonIsClose ? 'none' : 'auto';

    const watched = watch();

    const overlap = useOverlap({
        ...watched,
        id: event?.id,
        employeeId: watched?.employeeId?.value,
        branchId: props?.branchId,
    });



    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (values) => {
            log.debug('onSubmit.hasId', event?.id);
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data: any = formFields.toGql(values, {});

            log.debug('onSubmit.data', JSON.stringify(data, null, 2));

            const d = new Date(values?.planed);
            const [HH, mm] = values?.time?.split(':')?.map((t: string) => parseInt(t, 10));
            const planed = new Date(d?.getFullYear(), d?.getMonth(), d?.getDate(), HH, mm);

            log.debug('onSubmit', { formState: event });

            const resolvedData = {
                // subprojektId: Int;
                subprojektId: event?.subprojekt?.id,
                ...pick(
                    data,
                    // mitarbeiterIdSachbearbeiter: Int!;
                    'mitarbeiterIdSachbearbeiter',
                    // typSnippetId: Int;
                    'typSnippetId',
                    // dauer: Int!;
                    'dauer'
                ),
                // bemerkung: String!;
                bemerkung: data?.bemerkung || ' ',
                // prio: Int;
                ...(Boolean(values.prio) ? { prio: 1 } : { prio: 0 }),
                // erledigt: AWSDateTime;
                ...(Boolean(values?.done) ? { erledigt: new Date() } : { erledigt: null }),
                // geplant: AWSDateTime;
                ...(Boolean(values?.planed) && { geplant: new Date(planed) }),
            };


            log.debug('onSubmit.resolved', JSON.stringify(resolvedData, null, 2));

            const response = event?.id ?
                await onUpdateEvent({ id: event.id, version: event?.version, data: resolvedData, forceOverwrite: true }) :
                await onCreateEvent({ data: { ...resolvedData, ...(employeeId && { mitarbeiterIdUrheber: employeeId }) } });

            log.debug('onSubmit.response', JSON.stringify(response, null, 2));

            return {
                ...response,
                context: {
                    subproject: event?.subprojekt,
                    ...response?.context,
                }
            }
        },
        [event, employeeId]
    );

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    const onCreateWattro = React.useCallback((data: any) => {
        if (!event?.id && isAufbau && data?.projekttyp?.name === 'Trocknung') {
            dialog.onOpen('createWattro', data)
        }
    }, [event, isAufbau, dialog])

    const onSubmitWithOnClose = React.useCallback(
        async (values: any) => {
            const response: any = await onSubmit(values);
            onReset();
            onCreateWattro(response.context.subproject)
        },
        [onSubmit, onReset, onCreateWattro]
    );

    const onSelectType = (option: any) => setIsAufbau(option?.label === 'Aufbau')
    const onSetProjectSearchModal = React.useCallback(() => {
        dispatch?.({
            type: 'persistFormState',
            data: {
                ...(formState?.title && { title: formState?.title }),
                ...(formState?.id && { id: formState?.id }),
                ...(formState?.version && { version: formState?.version }),
                ...pick(watched,
                    'planed',
                    'duration',
                    'employeeId',
                    'client',
                    'time',
                    'typeSnippetId',
                    'comment',
                    'done',
                    'prio',)
            }
        });
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.ProjectSearch,
            },
        });
        onOpen?.();
    }, [watched, formState?.title, formState?.id, formState?.version])


    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel-event" variant="outline" onClick={onReset}>
                        {cancelButtonName}
                    </Button>
                    <HasPermission resource="project.schedule" permission={permssionType}>
                        <Button
                            data-test-id="button-save-event"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                            isDisabled={overlap.hasAbsenceOverlap || overlap.hasEventOverlap}
                        >
                            Speichern
                        </Button>
                    </HasPermission>
                </HStack>
            </ModalHeader>
            <ModalBody pb={6} data-test-id="event-modal-body" cursor={cursor}>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4} pointerEvents={pointerEvents}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    renderers,
                                    context: { onSetProjectSearchModal, onSelectType, branchId: props?.branchId },
                                })}
                            </React.Fragment>
                        ))}
                        {(overlap.hasAbsenceOverlap || overlap.hasEventOverlap) && (
                            <GridItem colSpan={2} >
                                <Alert status="warning">
                                    <AlertIcon />
                                    {overlap.hasAbsenceOverlap
                                        ? 'Es liegt bereits eine Abwesenheit für diesen Mitarbeiten an diesem Datum vor.'
                                        : 'Es kommt zu einer zeitlichen Terminüberschneidung.'}
                                </Alert>
                            </GridItem>
                        )}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent >
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProjectSearchModalContent = ({
    searchEntity = 'projektLfdNr',
    branchId: controlledBranchId,
    selectedDate,
}: {
    id?: string;
    searchEntity?: string;
    modalStateOnSelect?: any;
    selectedDate?: Date;
} & CalendarProps) => {
    const { dispatch, state } = useModal();
    const { formState = {} } = state;
    const branchId = controlledBranchId || formState?.branchId;

    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const { columns, loading, pageCount, projects, fetchData } = useSearchProject();

    const fetchWithFilter = React.useCallback(
        (args: any) => {
            const orderBy = { projekt: { anlageDatum: 'desc' } };
            const date = selectedDate ? new Date(selectedDate) : new Date();
            const variables = {
                searchInput: {
                    // ...(branchId && { niederlassungId: branchId }), // No more needed since UCPW-304
                    ...(debouncedSearchTerm
                        ? { [searchEntity]: parseInt(debouncedSearchTerm, 10) }
                        : {
                            anlageBis: format(date, 'yyyy-MM-dd'),
                            anlageAb: format(subMonths(date, 6), 'yyyy-MM-dd'),
                        }),
                },
                ...args,
                orderBy,
            };

            log.debug('ProjectSearchModalContent.fetchWithFilter', { variables });

            fetchData(variables);
        },
        [searchTerm, debouncedSearchTerm, branchId, selectedDate]
    );

    return (
        <ModalContent rounded="none" maxWidth="container.xl">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                p={5}
                borderColor="gray.200"
            >
                Projekt auswählen
                <HStack>
                    <Tooltip label="Suche nach laufender Nummer" placement="top" hasArrow>
                        <InputGroup width={400}>
                            <InputLeftElement pointerEvents="none" color="gray.400">
                                <BsSearch />
                            </InputLeftElement>
                            <Input
                                type="search"
                                placeholder="Suche"
                                value={searchTerm}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setSearchTerm(e.target.value)
                                }
                            />
                        </InputGroup>
                    </Tooltip>
                </HStack>
            </ModalHeader>
            <ModalBody p={0}>
                <DataTable<any>
                    columns={columns}
                    hiddenColumns={tables.projectSearch.hiddenColumns}
                    loading={loading}
                    data={projects}
                    pageCount={pageCount}
                    fetchData={fetchWithFilter}
                    onClick={(row: any) => {
                        const { projekt } = row?.original;
                        const client = getClient(row?.original?.beteiligte);
                        dispatch?.({
                            type: 'setModal',
                            data: { modal: Modals.CreateEvent },
                        });
                        dispatch?.({
                            type: 'keepFormState',
                            data: {
                                subprojekt: {
                                    id: row?.original?.id,
                                },
                                object: join([
                                    projekt.strasse,
                                    join([projekt.plz, projekt.strasse], ' '),
                                ]),
                                client: join([
                                    client?.firma1,
                                    client?.firma2,
                                    client?.name,
                                    client?.vorname,
                                ]),
                                subprojectId: generateProjectNumber(
                                    projekt?.anlageDatum,
                                    projekt?.niederlassung?.nummer,
                                    projekt.lfdNr,
                                    row?.original?.lfdNr
                                ),
                                row,
                            },
                        });
                    }}
                />
            </ModalBody>
        </ModalContent>
    );
};

import { Portal } from '@chakra-ui/react';
import { DataTable, PlusButton } from '@ucc/react/ui';
import { useAgreementPositions } from '~/pages/master-data/ui/GeneralAgreements/hooks/useAgreementPositions';
import { useSearchedAgreementPositions } from '~/pages/master-data/ui/GeneralAgreements/hooks/useSearchedAgreementPositions';
import { useDebounce, useSearchFilter } from '~/hooks';

type Props = {
    activeAgreementId: number,
    containerRef?: any
}

export function AgreementPositions({ activeAgreementId, containerRef }: Props) {
    const { value: searchTerm, filter } = useSearchFilter()
    const debouncedFilter = useDebounce(filter, 500)

    return searchTerm ?
        <SearchedPositions activeAgreementId={activeAgreementId} containerRef={containerRef} filter={debouncedFilter} /> :
        <Positions activeAgreementId={activeAgreementId} containerRef={containerRef} />

}

function SearchedPositions({ activeAgreementId, filter = {}, containerRef }: Props & { filter?: Record<string, string | number> }) {
    const { plusButtonProps, ...tableProps } = useSearchedAgreementPositions(activeAgreementId, filter);
    return (
        <>
            <Portal containerRef={containerRef}>
                <PlusButton {...plusButtonProps} />
            </Portal>
            <DataTable {...tableProps} />
        </>
    )
}

function Positions({ activeAgreementId, containerRef }: Props) {
    const { plusButtonProps, fetchData, ...tableProps } = useAgreementPositions(activeAgreementId);
    return (
        <>
            <Portal containerRef={containerRef}>
                <PlusButton {...plusButtonProps} />
            </Portal>
            <DataTable fetchData={fetchData} {...tableProps} />
        </>
    )
}
import React from 'react';
import { withRenderers } from '~/utils/withRenderers';
import { table } from '~/pages/projects/meta/data/emergencies.schema';
import { useQuery } from '@apollo/client';
import { ListNotdienstprojektDocument, SortOrder } from '~/gql/ucpw/graphql';
import { useDialog } from '~/hooks/useDialog';

export const useEmergencyProjects = () => {
    const { onOpen } = useDialog()
    const { data, loading } = useQuery(
        ListNotdienstprojektDocument,
        {
            context: { clientName: 'ucpw' },
            variables: {
                limit: 500,
                orderBy: [{ createdAt: SortOrder.Desc }],
                filter: {}
            }
        })
    const emergencyProjects = data?.items?.items || []

    const controls = [
        {
            title: 'Projekt zuordnen',
            props: {
                onClick: (row: any) => {
                    onOpen?.('selectProject', row.original)
                }
            },
        },
        'divider',
        {
            title: 'Wattro Projekt öffnen',
            props: {
                onClick: (row: any) => window.open(`${import.meta.env.VITE_WATTRO_URL}/ui/project/by_hid/${row?.original?.humanid}`, '_blank')
            }
        }
    ]



    const columns = React.useMemo(() => withRenderers(table.columns, controls), [controls]);

    const sortTimestamps = (data: any[] = []) =>
        Array.isArray(data) && data.length ?
            [...data]?.sort?.((a: any, b: any) => {
                const timestampA = a?.finishedAt || a?.startedAt || a?.createdAt
                const timestampB = b?.finishedAt || b?.startedAt || b?.createdAt
                return timestampA < timestampB ? 1 : timestampA > timestampB ? -1 : 0
            }) :
            data

    return {
        data: sortTimestamps(emergencyProjects),
        loading,
        columns,
    };
};

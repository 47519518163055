import { useRef } from 'react';
import { SimpleGrid, GridItem } from '@chakra-ui/react';
import { Card, CardActions, CardContent, CardHeader, CardHeading } from '@ucc/react/ui';
import { PageHeader } from '~/components/Layout/PageHeader';
import { HasPermission } from '~/layout/HasPermission';
import { AgreementPositions, Agreements } from '~/pages/master-data/ui/GeneralAgreements';
import { useGeneralAgreements } from './hooks/useGeneralAgreements';
import { SearchFilter } from '~/components/SearchFilter';

export function GeneralAgreements({ title }: { title: string }) {
    const ref = useRef<HTMLDivElement>(null);
    const agreements = useGeneralAgreements()
    const activeAgreementId = agreements?.activeGeneralAgreement?.id
    const colSpans = { base: 6, sm: 6, md: 6, lg: 6, }

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
            </PageHeader>
            <SimpleGrid columns={6} spacing={5} p={6}>
                <GridItem colSpan={{ ...colSpans, xl: 2 }}>
                    <Agreements {...agreements} />
                </GridItem>
                <GridItem colSpan={{ ...colSpans, xl: 4 }}>
                    <Card boxShadow="none">
                        <CardHeader>
                            <CardHeading>RV-Positionen</CardHeading>
                            <HasPermission resource="masterData.agreements" permission="create">
                                <CardActions>
                                    <SearchFilter
                                        filterOptions={[
                                            { label: 'Beschreibung', value: 'beschreibungPrefix' },
                                            { label: 'Nr', value: 'nrPrefix' }
                                        ]}
                                    />
                                    <div ref={ref} />
                                </CardActions>
                            </HasPermission>
                        </CardHeader>
                        <CardContent>
                            <AgreementPositions
                                containerRef={ref}
                                activeAgreementId={activeAgreementId as number} />
                        </CardContent>
                    </Card>
                </GridItem>
            </SimpleGrid>
        </>
    );
}





import React from 'react';
import { getOrderByProps } from '~/utils';

export function useFetchDataWithFilter(
    fetchData: (args: any, cb?: (args: any) => void) => void,
    data?: {
        filter?: Record<string, any>;
        sortBy?: any[];
        orderBy?: any[];
        limit?: number;
        searchInput?: Record<string, any>;
        searchTerm?: string;
        [key: string]: any;
    }
) {
    return React.useCallback(
        (args?: Record<string, any>, cb?: (args?: any) => void) => {
            const orderBy = data?.orderBy || [];
            fetchData(
                {
                    ...args,
                    ...(data || {}),
                    ...getOrderByProps(args, orderBy),
                },
                cb
            );
        },
        [data, fetchData]
    );
}

import React from 'react';
import { Box, MenuOptionGroup, MenuItemOption, MenuButton, IconButton, Menu, MenuList, Portal } from '@chakra-ui/react';
import { SearchField } from '~/components/Form/SearchField';
import { BiFilterAlt } from "react-icons/bi";
import { useSearchFilter } from '~/hooks';

type Props = {
    defaultFilterOption?: string;
    filterOptions?: {
        label: string;
        value: string;
    }[]
}

export const SearchFilter = ({ filterOptions, defaultFilterOption }: Props) => {
    const { value, onChange, onPrefixChange, prefix } = useSearchFilter()
    const defaultValue = prefix || defaultFilterOption || filterOptions?.[0]?.value || ''

    React.useEffect(() => {
        defaultValue && onPrefixChange(defaultValue)
    }, [defaultValue])

    return (
        <Box display="flex">
            <SearchField
                borderRightRadius={0}
                borderRightWidth={0}
                _focus={{
                    boxShadow: "none",
                    borderColor: 'gray.200'
                }}
                outline="none"
                onChange={onChange}
                value={value}
                w={220}
            />
            <Menu
                closeOnSelect={false}>
                <MenuButton
                    colorScheme='blue'
                    as={IconButton}
                    borderLeftRadius="0"
                    aria-label='Options'
                    icon={<BiFilterAlt />}
                    variant="outline"
                    borderColor="gray.200"
                    color="gray.400"
                    borderLeftWidth={0}

                />
                {/** 
                 *  FIXME: "Blocked aria-hidden on an element because its descendant retained focus."  
                 *  {isOpen && ( ... )}
                 * */}
                <Portal>
                    <MenuList w="auto" zIndex={99999}>
                        <MenuOptionGroup
                            type='radio'
                            defaultValue={defaultValue}
                            onChange={onPrefixChange}>
                            {filterOptions?.map((option, idx) => (
                                <MenuItemOption value={option.value} key={`${option.value}.${idx}`}>{option.label}</MenuItemOption>
                            ))}
                        </MenuOptionGroup>
                    </MenuList>
                </Portal>
            </Menu>
        </Box>
    )
}